.index-module__quoteItem___I0Sbx {
  padding: 15px 10px;
  margin-bottom: 1em;
  border-radius: 0.75em;
  background-color: var(--quote-item-bg);
}
.index-module__quoteItem___I0Sbx:last-child {
  margin-bottom: 0;
}
.index-module__quoteItem___I0Sbx .index-module__quoteMessage___DDb7B {
  font-size: 18px;
  line-height: 24px;
}
.index-module__quoteItem___I0Sbx .index-module__quoteMessage___DDb7B p {
  margin-bottom: 0.25em;
}
.index-module__quoteItem___I0Sbx .index-module__quoteMessage___DDb7B blockquote {
  padding: 0 1em;
  border-left: 0.25em solid #dadada;
}
.index-module__quoteItem___I0Sbx .index-module__quoteMessage___DDb7B code {
  padding: 0.2em 0.4em;
  font-size: 85%;
  border-radius: 6px;
  background-color: #f7f7f5;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.index-module__quoteItem___I0Sbx .index-module__quoteMessage___DDb7B pre {
  padding: 16px;
  font-size: 85%;
  overflow: auto;
  background-color: #f7f7f5;
  border-radius: 6px;
}
.index-module__quoteItem___I0Sbx .index-module__quoteMessage___DDb7B pre > code {
  font-size: unset;
  border-radius: unset;
  background-color: unset;
  padding: unset;
}
.index-module__quoteItem___I0Sbx .index-module__quoteAuthorAvatar___44wDj {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  margin-right: 0.5em;
}
.index-module__quoteItem___I0Sbx .index-module__quoteAuthorName___3z43h {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  align-self: center;
}
.index-module__quoteItem___I0Sbx .index-module__quoteAuthorRow___UfXkc {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  gap: 5px;
  margin-bottom: 0.25em;
}
.index-module__quoteItem___I0Sbx .index-module__quoteInfoRow___g5Ep-,
.index-module__quoteItem___I0Sbx .index-module__quoteMeta___GlWtY {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  gap: 5px;
  color: var(--palatte-fg);
  opacity: 55%;
}