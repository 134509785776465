.index-module__searchPageContainer___vFO02 {
  max-width: 1280px;
  width: 100%;
  margin: auto;
}
@media (max-width: 639px) {
  .index-module__searchPageContainer___vFO02 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.index-module__loginLink___cYvux {
  text-align: right;
  font-size: 12px;
  line-height: 20px;
  padding-top: 10px;
}
.index-module__searchNav___3OkNR {
  display: flex;
  margin: 1em 0;
  color: var(--text-search);
}
.index-module__searchForm___rppTb {
  display: flex;
  flex: 1;
  padding: 2px;
  border: 1px solid var(--box-border);
  border-radius: 6px;
}
.index-module__searchForm___rppTb:focus-within {
  border: 1px solid var(--box-border-focus);
}
.index-module__searchForm___rppTb .index-module__searchBar___Z4Is7 {
  font-size: 16px;
  line-height: 22px;
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  border-radius: inherit;
  flex: 1;
}
.index-module__searchForm___rppTb .index-module__searchBar___Z4Is7:focus {
  border: none;
  outline: none;
}
.index-module__searchForm___rppTb .index-module__submitButton___26pwG {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: inherit;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
  cursor: pointer;
  opacity: 0.6;
}
.index-module__searchForm___rppTb .index-module__submitButton___26pwG:hover {
  opacity: 1;
}
.index-module__searchForm___rppTb .index-module__submitButton___26pwG:focus {
  border: none;
  outline: none;
}
.index-module__searchForm___rppTb .index-module__submitButton___26pwG:hover {
  opacity: 1;
}
.index-module__randomButton___wFs-d {
  font-size: 16px;
  line-height: 22px;
  margin-left: 10px;
  border: 1px solid var(--box-border);
  border-radius: 6px;
  background-color: var(--button-background);
}
.index-module__randomButton___wFs-d:hover {
  border: 1px solid var(--box-border-focus);
  background-color: var(--button-background-focus);
}
.index-module__pageButtons___z8rkp {
  padding-top: 30px;
  padding-left: 10px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  color: var(--palatte-fg);
  opacity: 65%;
}